import * as React from 'react';
import { useState, useEffect, createContext } from 'react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { AssetsGQL, UserAccessFlagGQL } from '../Hooks/graphql';
import { CircularProgress } from "@mui/material";

const DCContext = createContext();

export const DentsuConsulting = (props) => {
    //MSAL Main States
    const { accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [userName, setUserName] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [SurveyAccessCheck, setSurveyAccessCheck] = useState(false);
    const [Assets, setAssets] = useState();

    //MSAL Fetch
    useEffect(() => {
        if (!isAuthenticated) {
            setUserName(null);
            setUserEmail(null);
        } else {
            setUserName(accounts[0].name);
            setUserEmail(accounts[0].username);
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (userEmail) {
            AssetsGQL().then(({ data }) => {
                setAssets(JSON.parse(data.data.appImages_AppStore));
            }).catch(error => {
                //to ensure that the entire page does not break if the query fails, because if the query fails and we try to get Assets.ATP.logo, it will fail to find Assets.ATP and will crash the entire page. 
                setAssets({
                    "App store": {},
                    "DeepLinks": {},
                    "Home Page": {},
                    "Logo": {},
                    "Pricing Tool": {},
                    "Survey": {},
                });
            });
        }
    }, [userEmail]);

    useEffect(() => {
        if (userEmail) {
            UserAccessFlagGQL({ userEmail })
                .then(({ data }) => {
                    setSurveyAccessCheck(JSON.parse(data.data.checkByUserEmail_Survey));
                }).catch(function (error) {
                    //console.log(error);
                });
        }
    }, [userEmail]);

    return (
        <DCContext.Provider value={{ userName, userEmail, SurveyAccessCheck, Assets }}>
            {Assets!==undefined ?
                props.children : <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 100 }}><CircularProgress /></div>
            }
        </DCContext.Provider>
    );
}
export default DCContext;
