import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';

//msal imports
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from "./components/auth/authConfig"

import registerServiceWorker from './registerServiceWorker';
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE);

export const msalInstance = new PublicClientApplication(msalConfig);

const rootElement = document.getElementById('root');

ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <Router>
                <App />
            </Router>
        </MsalProvider>
    </React.StrictMode>,
    rootElement);

registerServiceWorker();

