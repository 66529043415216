export const environment = {
    production: true,
    isShowAllModulesExplicitly: true,
    baselink: '',
    issuer: 'https://dentsuaegis-test.okta-emea.com/oauth2/default',
    clientId: '0oa8u0usuzfOyIkQK0i7'
};

export const msalEnvironment = {
    clientID: "ccffbc97-d9e3-4a9a-8d80-c045b70ce296",
    autority: "https://login.microsoftonline.com/6e8992ec-76d5-4ea5-8eae-b0c5e558749a",
    axiosURL: process.env.REACT_APP_AXIOS_URL,
    //axiosURL: "https://localhost:7260/"
}